import PlanningLeadMagnet from "components/sections/placeholders/PlanningLeadMagnet";
import SearchResults from "components/sections/placeholders/SearchResults";
import TeachingHero from "components/sections/placeholders/TeachingHero";
import * as React from "react";
import { Section } from "types/attheminute";
import TeachingBio from "./TeachingBio";
import TeachingGrid from "./TeachingGrid";

export interface PlaceholderSectionProps extends Section {
  type: "planningLeadMagnet" | "teachingHero";
}

const placeholderMap: Record<string, (props: Section) => JSX.Element> = {
  planningLeadMagnet: PlanningLeadMagnet,
  teachingHero: TeachingHero,
  teachingGrid: TeachingGrid,
  teachingBio: TeachingBio,
  searchResults: SearchResults,
};

export default function PlaceholderMapper(props: PlaceholderSectionProps) {
  const { type, sectionOptions } = props;

  const Component = placeholderMap[type];

  if (!Component) {
    return <div>Unimplemented</div>;
  }

  return React.createElement(Component, { sectionOptions });
}
