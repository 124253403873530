import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import NextImage from "next/legacy/image";
import { Section } from "types/attheminute";
import atmProjectsImage from "../../../../public/images/atm_projects.png";
// import impactTextImage from "../../../../public/images/impact-text.png";
// import wigglyTextImage from "../../../../public/images/wiggly-text.png";
import * as Styled from "./ForegroundBackgroundHeroImageSection.styled";

export interface ForegroundBackgroundHeroImageSectionProps extends Section {
  dataTestId?: string;
}

function ForegroundBackgroundHeroImageSection(
  props: ForegroundBackgroundHeroImageSectionProps
) {
  const { dataTestId, sectionOptions } = props;

  return (
    <SectionWrapper
      data-testid={dataTestId}
      {...getSectionWrapperProps(sectionOptions || {})}
    >
      <Styled.HeroHeader>
        <Styled.BkgImageContainer>
          <NextImage
            src={atmProjectsImage}
            alt="London"
            layout="responsive"
            width={591}
            height={162}
            priority
            unoptimized
          />
        </Styled.BkgImageContainer>
        {/* <Styled.ContentContainer>
          <Styled.ForegroundImageContainer>
            <NextImage
              src={wigglyTextImage}
              alt="at the minute"
              layout="responsive"
              width={338}
              height={69}
              priority
              unoptimized
            />
          </Styled.ForegroundImageContainer>
        </Styled.ContentContainer> */}
      </Styled.HeroHeader>
    </SectionWrapper>
  );
}

export default ForegroundBackgroundHeroImageSection;
