import * as Checkbox from "@radix-ui/react-checkbox";
import styled from "styled-components";
import spacing from "styles/spacing";
import { textLabelStyles } from "styles/typography";

export const Root = styled(Checkbox.Root)`
  background-color: var(--color-background);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-inputOutline);

  :hover {
    background-color: var(--color-backgroundHover);
  }
`;

export const Indicator = styled(Checkbox.Indicator)`
  color: var(--color-text);
`;

export const Label = styled.label`
  ${textLabelStyles}
  padding-left: ${spacing.xs};
  flex: 1;
`;
