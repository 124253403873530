import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import StoryblokRichtext from "components/ui/StoryblokRichtext";
import Image from "next/image";
import { ImageOrFile, Section } from "types/attheminute";
import { DocumentNode } from "types/storyblok-rich-text";
import * as Styled from "./SideTextAndImage.styled";
import FixedRatioBox from "components/ui/FixedRatioBox/FixedRatioBox";

export interface SideTextAndImageProps extends Section {
  dataTestId?: string;
  content?: DocumentNode;
  className?: string;
  image?: ImageOrFile;
  flipContent?: boolean;
}

export default function SideTextAndImageSection(props: SideTextAndImageProps) {
  const {
    sectionOptions,
    content,
    image,
    dataTestId = "side-text-and-image-section",
    className,
    flipContent,
  } = props;

  const imageAspectRatio =
    (image?.dimensions?.width ?? 1) / (image?.dimensions?.height ?? 1);

  return (
    <SectionWrapper
      data-testid={dataTestId}
      className={className}
      {...getSectionWrapperProps(sectionOptions)}
    >
      <Styled.Wrapper $flip={flipContent}>
        <Styled.TextContent>
          {content && content.content && (
            <StoryblokRichtext content={content.content} />
          )}
        </Styled.TextContent>
        {image?.url && (
          <Styled.ImageWrapper>
            <FixedRatioBox
              outerRatio={imageAspectRatio}
              innerRatio={imageAspectRatio}
            >
              <Image src={image?.url} alt={image?.alt ?? ""} fill />
            </FixedRatioBox>
          </Styled.ImageWrapper>
        )}
      </Styled.Wrapper>
    </SectionWrapper>
  );
}
