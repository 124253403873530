import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { fontFamilyBrand, mFont } from "styles/typography";
import spacing from "styles/spacing";
import Underline from "../TeachingHero/Underline";

export const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.xs};
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${screenSize.minDesktop`
    grid-gap: ${spacing.lg};
    grid-template-columns: repeat(10, minmax(0, 1fr));
  `}
`;

export const Picture = styled.div`
  padding: 0 3.75rem;
  grid-column: span 2;
  margin: 0 auto;

  ${screenSize.minDesktop`
    grid-column: span 4;
    margin-left: 0;
  `}
`;

export const Bio = styled.div`
  grid-column: span 2;

  ${screenSize.minDesktop`
  grid-column: span 6;
  `}
`;

export const Mission = styled.div`
  grid-column: span 2;

  margin-top: ${spacing.md};
  margin-bottom: ${spacing.xl};

  ${screenSize.minDesktop`
    grid-column: span 4;
  `}
`;

export const MissionTitle = styled.div`
  font-family: ${fontFamilyBrand};
  font-size: ${mFont};
  margin-bottom: ${spacing.md};
`;

export const MissionMain = styled.div`
  font-family: ${fontFamilyBrand};
  font-size: ${mFont};

  > div {
    margin: ${spacing.sm} 0;
  }

  ${screenSize.minDesktop`
    margin-left: ${spacing.lg};
  `}
`;

export const MissionHighlight = styled.span`
  color: var(--color-warmAccent);
`;

export const TeachingUnderline = styled(Underline)`
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
`;

export const SubscribeSection = styled.div`
  grid-column: span 2;
  padding: ${spacing.lg};
  background: var(--color-lightShades);

  ${screenSize.minDesktop`
    margin-left: 0;
    grid-column: 5 / 11;
  `}
`;
