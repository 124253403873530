import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { fontFamilyLabel, xlFont, TextH3, TextH4 } from "styles/typography";
import spacing from 'styles/spacing';

export const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.xs};
  grid-template-columns: minmax(0, 1fr);

  ${screenSize.minTablet`
  grid-gap: ${spacing.md};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${screenSize.minDesktop`
  grid-gap: ${spacing.lg};
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}
`;

export const GridItem = styled.div`
  grid-column: span 1;
`;

export const LinkContainer = styled.a`
  position: relative;
  width: 100%;
  margin: 0px;
  overflow: hidden;
  text-decoration: none;
`;

export const Title = styled(TextH3)`
  color: inherit;
  margin-top: 0;
  margin-bottom: ${spacing.sm};
  font-size: ${xlFont};
`;

export const Subtitle = styled(TextH4)`
  font-family: ${fontFamilyLabel};
`;
