import React from "react";
import StoryblokRichtext from "components/ui/StoryblokRichtext";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import * as Styled from "./AdvancedGrid.styled";
import { Section } from "types/attheminute";

export interface AdvancedGridSectionProps extends Section {
  components?: any;
  columns?: string;
  columnsTablet?: string;
  columnsDesktop?: string;
  rows?: string;
  preFormatted?: boolean;
}

export default function AdvancedGridSection(props: AdvancedGridSectionProps) {
  const {
    components = [],
    columns = "1fr",
    columnsTablet = "1fr 1fr",
    columnsDesktop = "1fr 1fr",
    rows = undefined,
    preFormatted = false,
    sectionOptions,
    ...rest
  } = props;

  const gridColumns = columns;
  const gridRows = rows;

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)} {...rest}>
      <Styled.Grid
        preFormatted={preFormatted}
        columns={columns}
        columnsTablet={columnsTablet}
        columnsDesktop={columnsDesktop}
        rows={gridRows}
      >
        {components &&
          components.map((component: any) => {
            if (component.component === "blogSection") {
              return (
                component.content.content && (
                  <div key={component._uid}>
                    <StoryblokRichtext content={component.content.content} />
                  </div>
                )
              );
            }
            return null;
          })}
      </Styled.Grid>
    </SectionWrapper>
  );
}
