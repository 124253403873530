import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import * as Styled from "./Checkbox.styled";
export { Indicator, Root } from "./Checkbox.styled";

interface CheckboxProps {
  name: string;
  defaultChecked?: boolean;
  value?: RadixCheckbox.CheckedState;
  onChange?: (checked: RadixCheckbox.CheckedState) => void;
  children: React.ReactNode;
}

export default function Checkbox(props: CheckboxProps) {
  const { name, defaultChecked = true, value, onChange, children } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Styled.Root
        defaultChecked={defaultChecked}
        id={name}
        name={name}
        checked={value}
        onCheckedChange={onChange}
      >
        <Styled.Indicator>
          <CheckIcon />
        </Styled.Indicator>
      </Styled.Root>
      <Styled.Label htmlFor={name}>{children}</Styled.Label>
    </div>
  );
}
