import styled from "styled-components";
import spacing from "styles/spacing";
import layout from "styles/layout";
import screenSize from "styles/mediaQueries";
import { TextH1, TextP } from "styles/typography";

export const BkgImageContainer = styled.div`
  width: 100%;
  max-width: ${layout.expandedContentMaxWidth};

  ${screenSize.minTablet`
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  `}

  ${screenSize.minDesktop`
    width: 60%;
  `}
`;

export const HeroHeader = styled.div`
  background: ${(props) => props.background};
  position: relative;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export const ForegroundImageContainer = styled.div`
  width: 40%;
  height: auto;

  max-width: ${layout.blogMaxWidth};
  min-width: ${spacing.xxxl};

  ${screenSize.minTablet`
    width: 40%;
  `}

  ${screenSize.minDesktop`
    width: 40%;
  `}
`;

export const PromoText = styled(TextP)`
  z-index: 1;
  text-align: center;
  padding: 0 2.5rem;
  color: var(--color-contrast-mainBrandColour);
`;

export const Heading = styled(TextH1)`
  margin: 0;
  text-align: center;
  z-index: 1;
  margin-bottom: 2.375rem;
  color: var(--color-contrast-mainBrandColour);
`;
