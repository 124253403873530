import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { Content as DefaultContent } from "components/ui/Layout";
import spacing from "styles/spacing";
import { fontFamilyBrand } from "styles/typography";

export const ResourceGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: ${spacing.sm};

  ${screenSize.minTablet`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: ${spacing.md};
  `}

  ${screenSize.minDesktop`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: ${spacing.lg};
  `}
`;

export const Content = styled(DefaultContent)`
  display: flex;
  justify-content: center;
  padding-bottom: ${spacing.md};
`;

export const SeeMore = styled.a`
  font-family: ${fontFamilyBrand};
  text-decoration: none;
`;
