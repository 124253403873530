import * as React from "react";
import styled from "styled-components";

const FixedAspectRatioBox = styled.div<{ $aspectRatio: number }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: ${(props) => props.$aspectRatio};
`;

const InnerFixedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const InnerFixedAspectRatioBox = styled.div<{
  $aspectRatio: number;
}>`
  position: relative;
  aspect-ratio: ${(props) => props.$aspectRatio};

  ${(props) => {
    if (props.$aspectRatio >= 1) {
      return "width: 100%;";
    }
    return "height: 100%;";
  }}
`;

interface FixedRatioBoxProps {
  outerRatio: number;
  innerRatio: number;
  children?: React.ReactNode;
}

export default function FixedRatioBox(props: FixedRatioBoxProps) {
  const { outerRatio, innerRatio, children } = props;

  return (
    <FixedAspectRatioBox $aspectRatio={outerRatio}>
      <InnerFixedContainer>
        <InnerFixedAspectRatioBox $aspectRatio={innerRatio}>
          {children}
        </InnerFixedAspectRatioBox>
      </InnerFixedContainer>
    </FixedAspectRatioBox>
  );
}
