import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import { TextH1, TextP, TextSmallP } from "styles/typography";

interface InnerWrapperProps {
  isFlipped?: boolean;
}

export const InnerWrapper = styled.div<InnerWrapperProps>`
  width: 100%;
  margin: auto;
  max-width: 1225px;
  flex-direction: ${(props) => (props.isFlipped ? "row-reverse" : "row")};
  display: flex;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: none;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left
  `}

  ${screenSize.minDesktop`
    width: 100%;
  `}
`;

export const HeadingOne = styled(TextH1)`
  color: var(--color-dark);
  ${screenSize.minTablet`
    font-size: 2.8rem;
    line-height: 1.25;
  `}
`;

export const Subheading = styled(TextP)`
  color: var(--color-dark);
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${spacing.md};

  grid-template-columns: 1fr;

  ${screenSize.minTablet`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const CtaWrapper = styled.div`
  margin-top: ${spacing.lg};
`;

export const HeroImageWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
`;

export const UnstyledButton = styled.button`
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    justify-content: flex-start;
    align-items: flex-start;
  `}

  ${screenSize.minDesktop`
    flex-direction: row;
  `}
`;

export const Input = styled.input`
  border: none;
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: ${spacing.sm};
  margin-right: 0;
  border: 1px solid var(--color-inputOutline);
  width: 100%;
  max-width: 40rem;

  background: ${(props) =>
    props.disabled ? "var(--color-inputDisabled)" : "var(--color-background)"};

  ${screenSize.minTablet`
    margin-bottom: ${spacing.sm};
    width: auto;
    min-width: 28rem;
  `}

  ${screenSize.minDesktop`
    margin-right: ${spacing.sm};
    margin-bottom: 0;
  `}
`;

interface StatusTextProps {
  success?: boolean;
}

export const StatusText = styled(TextSmallP)<StatusTextProps>`
  color: ${({ success }) =>
    success ? "var(--color-lightAccent)" : "var(--color-darkAccent)"};
  text-align: center;
  margin-top: ${spacing.sm};

  ${screenSize.minTablet`
    text-align: left;
  `}
`;
