import { SectionOptions } from "components/ui/SectionWrapper";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import StoryblokRichtext from "components/ui/StoryblokRichtext";
import * as Styled from "./BlogSection.styled";
import { Section } from "types/attheminute";

// TODO: Use typings from here:
// https://github.com/MarvinRudolph/storyblok-rich-text-renderer/tree/master/packages/storyblok-rich-text-types

export interface BlogSectionProps extends Section {
  dataTestId?: string;
  content?: any; // TODO: content props
  ignoreMaxWidth?: boolean;
}

export default function BlogSection(props: BlogSectionProps) {
  const { dataTestId, content, ignoreMaxWidth, sectionOptions } = props;

  return (
    <SectionWrapper
      data-testid={dataTestId}
      {...getSectionWrapperProps(sectionOptions)}
    >
      <Styled.BlogContent $ignoreMaxWidth={ignoreMaxWidth}>
        {content && content.content && (
          <StoryblokRichtext content={content.content} />
        )}
      </Styled.BlogContent>
    </SectionWrapper>
  );
}
