import styled from "styled-components";
import spacing from "styles/spacing";
import layout from "styles/layout";
import screenSize from "styles/mediaQueries";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.md};
`;

// https://css-tricks.com/lobotomized-owls/
// > * + * This is the owl selector
export const ButtonsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-top: ${spacing.sm};
  }

  ${screenSize.minTablet`
      flex-direction: row;
      
      > * + * {
        margin-left: ${spacing.sm};
        margin-top: 0;
  `}
`;

interface WrapperProps {
  $flip?: boolean;
}
// Column reverse is for teaching home page to put the image first. Might need to allow the user to choose.
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column-reverse;

  gap: ${spacing.lg};

  ${screenSize.minTablet`
    flex-direction: ${(props: WrapperProps) =>
      props.$flip ? "row-reverse" : "row"}};
  `}
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  grid-area: img;
  justify-self: center;
  align-self: center;
  width: ${(props) => props.width};
  max-width: 100%;

  ${screenSize.minTablet`
    justify-self: end;
  `}
`;

export const TextContent = styled.article`
  flex: 1;
  margin: 0 auto;
  max-width: ${layout.blogMaxWidth};
  overflow: auto;
  display: flex;
  align-items: center;
`;
