import { PaddedContainer } from "components/ui/Layout";
import SectionWrapper, {
  SectionOptions,
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import TeachingResourceCard from "components/ui/TeachingResourceCard";
import Link, { LinkProps } from "next/link";
import { TextH2, TextP } from "styles/typography";
import { MinimalTeachingResource, Section } from "types/attheminute";
import * as Styled from "./TeachingResourceGrid.styled";

export interface SeeMoreLink {
  text: string;
  linkProps: LinkProps;
}

export interface TeachingResourceGridProps extends Section {
  heading?: string;
  resources?: MinimalTeachingResource[];
  seeMoreLink?: SeeMoreLink;
}

export default function TeachingResourceGrid(props: TeachingResourceGridProps) {
  const {
    heading: sectionHeading = "",
    sectionOptions,
    seeMoreLink,
    resources,
  } = props;

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      {sectionHeading && <TextH2>{sectionHeading}</TextH2>}
      <Styled.ResourceGrid>
        {resources &&
          resources.map((resource) => {
            const previewImage = resource?.previewImages?.[0];
            return (
              <TeachingResourceCard
                key={resource.fullSlug}
                fullSlug={resource.fullSlug}
                price={resource.price}
                heading={resource.heading || ""}
                previewImage={previewImage}
                isAvailableOnTPT={resource.isAvailableOnTPT}
              />
            );
          })}
      </Styled.ResourceGrid>
      {seeMoreLink ? (
        <PaddedContainer>
          <Styled.Content>
            <Link passHref legacyBehavior {...seeMoreLink.linkProps}>
              <Styled.SeeMore>
                <TextP>{seeMoreLink.text}</TextP>
              </Styled.SeeMore>
            </Link>
          </Styled.Content>
        </PaddedContainer>
      ) : null}
    </SectionWrapper>
  );
}
