import * as React from "react";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import * as Styled from "./EmailSubscribeImageText.styled";
import { ImageOrFile, Section } from "types/attheminute";
import useSubscribeToEmail from "hooks/useSubscribeToEmail";
import CtaButton from "components/ui/CtaButton";
import { ColourName, colourNames } from "styles/colours";
import NextImage from "next/legacy/image";
import { storyblokLoader } from "utils/storyblok";
import { TEACHING_EMAIL_LIST_ID } from "api";

export interface EmailSubscribeImageTextProps extends Section {
  dataTestId?: string;
  heading?: string;
  text?: string;
  image?: ImageOrFile;
  emailSubscribeSource?: string;
  emailSubscribeLists?: string[];
  emailSubscribeButtonText?: string;
  emailSubscribeButtonColorName?: ColourName;
  emailSubscribeSuccessText?: string;
}

export default function EmailSubscribeImageText(
  props: EmailSubscribeImageTextProps
) {
  const {
    dataTestId = "email-subscribe-image-text",
    heading = "Subscribe to our list!",
    text,
    emailSubscribeSource = "email-subscribe-image-text-section",
    emailSubscribeLists = [TEACHING_EMAIL_LIST_ID],
    emailSubscribeButtonText = "Subscribe",
    emailSubscribeButtonColorName = colourNames.MAIN_BRAND_COLOUR,
    emailSubscribeSuccessText = "Great! You'll receive an email shortly!",
    image,
    sectionOptions,
  } = props;

  const [email, setEmail] = React.useState("");
  const { subscribe, loading, data, error } = useSubscribeToEmail();

  let statusText = "";
  let success;

  if (data && !data.error && data.result !== "error") {
    statusText = emailSubscribeSuccessText;
    success = true;
  } else if (error || (data && data.result === "error")) {
    statusText =
      "We've hit an error! Please try again or contact us at support@attheminute.com";
    success = false;
  }

  return (
    <SectionWrapper
      data-testid={dataTestId}
      {...getSectionWrapperProps(sectionOptions)}
    >
      <Styled.InnerWrapper>
        <Styled.Grid>
          <Styled.TextContainer>
            {heading ? <Styled.HeadingOne>{heading}</Styled.HeadingOne> : null}
            {text ? <Styled.Subheading>{text}</Styled.Subheading> : null}

            {/* Subscribe form */}
            {emailSubscribeSource &&
            emailSubscribeLists &&
            emailSubscribeLists.length > 0 ? (
              <Styled.Form
                onSubmit={(e) => {
                  e.preventDefault();
                  subscribe(email, {
                    source: emailSubscribeSource,
                    lists: emailSubscribeLists,
                  });
                }}
              >
                <Styled.Input
                  placeholder="Email address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled={loading}
                  required
                />
                <Styled.UnstyledButton
                  aria-label="Subscribe to mailing list"
                  type="submit"
                  disabled={loading}
                >
                  <CtaButton
                    colourName={emailSubscribeButtonColorName}
                    appearance="square"
                    aria-label={emailSubscribeButtonText}
                    disabled={loading}
                  >
                    {emailSubscribeButtonText}
                  </CtaButton>
                </Styled.UnstyledButton>
              </Styled.Form>
            ) : null}
            <Styled.StatusText success={success}>
              {statusText}
            </Styled.StatusText>
          </Styled.TextContainer>

          {image && image.url ? (
            <Styled.TextContainer>
              <Styled.HeroImageWrapper>
                <NextImage
                  alt={image && image.alt ? image.alt : ""}
                  src={image.url}
                  width={image.dimensions ? image.dimensions.width : 1}
                  height={image.dimensions ? image.dimensions.height : 1}
                  loader={storyblokLoader}
                />
              </Styled.HeroImageWrapper>
            </Styled.TextContainer>
          ) : null}
        </Styled.Grid>
      </Styled.InnerWrapper>
    </SectionWrapper>
  );
}
