import styled from "styled-components";
import spacing from "styles/spacing";
import layout from "styles/layout";
import screenSize from "styles/mediaQueries";

export const Content = styled.article`
  margin: 0 auto;
  max-width: ${layout.blogMaxWidth};
`;

interface GridProps {
  columns?: string;
  columnsTablet?: string;
  columnsDesktop?: string;
  rows?: string;
  preFormatted?: boolean;
}

export const Grid = styled.div<GridProps>`
  width: 100%;
  display: grid;

  grid-template-columns: ${(props) => props.columns};
  grid-template-rows: ${(props) => props.rows};
  grid-gap: ${spacing.lg};

  ${screenSize.minTablet`
  ${(props: GridProps) =>
    props.columnsTablet && `grid-template-columns: ${props.columnsTablet};`};
  `}

  ${screenSize.minDesktop`
  ${(props: GridProps) =>
    props.columnsTablet && `grid-template-columns: ${props.columnsDesktop};`};
  `}

  ${(props) =>
    props.preFormatted &&
    `
        & p {
            white-space: pre;
        }
        overflow: auto;
    `}
`;
