import Link from "components/ui/Link";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import { colourNames } from "styles/colours";
import * as Styled from "./TeachingGrid.styled";
import { Section } from "types/attheminute";

export default function TeachingGrid({ sectionOptions: overriddenOptions }: Section) {
  const theme = "light" as "light" | "dark";
  const sectionOptions = {
    theme: theme,
    backgroundColour: colourNames.LIGHT_SHADES,
    contentWidth: "regular" as const,
    ...overriddenOptions,
  };

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      <Styled.Container>
        <Styled.GridItem>
          <Link href="/teaching/shop">
            <Styled.Title>Resources</Styled.Title>
            <Styled.Subtitle>{"Shop & free resources"}</Styled.Subtitle>
          </Link>
        </Styled.GridItem>
        <Styled.GridItem>
          <Link href="/teaching/guide">
            <Styled.Title>Guides</Styled.Title>
            <Styled.Subtitle>{"For some tips & tricks"}</Styled.Subtitle>
          </Link>
        </Styled.GridItem>
        <Styled.GridItem>
          <Link href="/teaching/blog">
            <Styled.Title>Blog</Styled.Title>
            <Styled.Subtitle>{"Actually useful PD"}</Styled.Subtitle>
          </Link>
        </Styled.GridItem>
        <Styled.GridItem>
          <Link href="/teaching/tools">
            <Styled.Title>Tools</Styled.Title>
            <Styled.Subtitle>{"Save precious time"}</Styled.Subtitle>
          </Link>
        </Styled.GridItem>
      </Styled.Container>
    </SectionWrapper>
  );
}
