import Card from "components/atoms/Card";
import SideImageContentPreview from "components/molecules/contentPreviewLayouts/SideImageContentPreview";
import {
  isArticleSearchItem,
  isTeachingResourceSearchItem,
} from "components/organisms/SiteSearch/util";
import TeachingResourceGrid from "components/sections/TeachingResourceGrid/TeachingResourceGrid";
import Breadcrumbs from "components/ui/Breadcrumbs";
import SectionWrapper, {
  SectionOptions,
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import Checkbox from "components/ui/formElements/Checkbox";
import { FormItem } from "components/ui/formElements/presentational";
import useSiteSearch from "hooks/useSiteSearch";
import { useRouter } from "next/router";
import * as React from "react";
import { TextH1, TextH2, TextLabel } from "styles/typography";
import { MinimalTeachingResource, Section } from "types/attheminute";
import * as Styled from "./SearchResults.styled";
import searchProductJson from "../../../../../public/files/search-product.json";
import ContentGrid, {
  PromotedVertical,
  RegularItem,
} from "components/organisms/ContentGrid";
import StackedImageContentPreview from "components/molecules/contentPreviewLayouts/StackedImageContentPreview";
import Link, { StyledLink } from "components/ui/Link/Link";

export interface SearchFilters {
  showProducts?: boolean;
  showArticles?: boolean;
}

const allMinimalTeachingResources: { [key: string]: MinimalTeachingResource } =
  {};

(searchProductJson as MinimalTeachingResource[]).forEach((resource) => {
  allMinimalTeachingResources[resource.id] = resource;
});

export default function SearchResults({
  sectionOptions: overriddenOptions,
}: Section) {
  const sectionOptions: SectionOptions = {
    disableOverlay: true,
    removeTopPadding: true,
    ...overriddenOptions,
  };
  const [filtersInternal, setInternalFilters] = React.useState<SearchFilters>({
    showArticles: true,
    showProducts: true,
  });
  const [productPage, setProductPage] = React.useState(0);
  const [articlePage, setArticlePage] = React.useState(0);

  const router = useRouter();
  const searchQuery = router.query.q;
  const searchResults = useSiteSearch(
    typeof searchQuery === "string" ? searchQuery : undefined
  );

  const breadcrumbs = [
    { link: "/teaching", content: "Teaching" },
    { link: "/teaching/search", content: "Search" },
    { content: searchQuery },
  ];

  const teachingResourceMatches = searchResults.filter(
    isTeachingResourceSearchItem
  );
  const blogArticleMatches = searchResults.filter(isArticleSearchItem);

  const showProducts =
    filtersInternal.showProducts && teachingResourceMatches.length > 0;
  const showArticles =
    filtersInternal.showArticles && blogArticleMatches.length > 0;

  const productsPerPage = 8;
  const numProductPages = Math.ceil(
    teachingResourceMatches.length / productsPerPage
  );

  const articlesPerPage = 6;
  const numArticlePages = Math.ceil(
    blogArticleMatches.length / articlesPerPage
  );

  React.useEffect(() => {
    setProductPage(0);
    setArticlePage(0);
  }, [searchQuery]);

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <TextH1 noMarginTop>Search results: {searchQuery}</TextH1>
      <FormItem>
        <Checkbox
          name="showProducts"
          value={filtersInternal.showProducts}
          onChange={(checked) => {
            setInternalFilters({
              ...filtersInternal,
              showProducts: checked === "indeterminate" ? false : checked,
            });
          }}
        >
          Show shop products
        </Checkbox>
      </FormItem>
      <FormItem>
        <Checkbox
          name="showArticles"
          value={filtersInternal.showArticles}
          onChange={(checked) => {
            setInternalFilters({
              ...filtersInternal,
              showArticles: checked === "indeterminate" ? false : checked,
            });
          }}
        >
          Show articles
        </Checkbox>
      </FormItem>
      <Styled.Results>
        {showProducts ? (
          <>
            <TextH2>Products</TextH2>
            <TeachingResourceGrid
              resources={teachingResourceMatches
                .slice(0, productPage * productsPerPage + productsPerPage)
                .map((resource) => allMinimalTeachingResources[resource.id])}
              sectionOptions={{
                removeLeftPadding: true,
                removeRightPadding: true,
                removeTopPadding: true,
                removeBottomPadding: true,
              }}
            />
            {productPage < numProductPages - 1 ? (
              <StyledLink
                onClick={() => setProductPage(productPage + 1)}
                style={{ marginTop: "1.25rem" }}
              >
                Show more
              </StyledLink>
            ) : null}
          </>
        ) : null}

        {showArticles ? (
          <>
            <TextH2>Articles</TextH2>
            <ContentGrid>
              {blogArticleMatches
                .slice(0, articlePage * articlesPerPage + articlesPerPage)
                .map((searchResult) => {
                  return (
                    <RegularItem key={searchResult.id}>
                      <Card key={searchResult.id}>
                        <StackedImageContentPreview
                          heading={searchResult.title}
                          description={searchResult.description}
                          image={searchResult.previewImage}
                          imageAlt={searchResult.previewImageAlt}
                          fullSlug={searchResult.slug}
                        />
                      </Card>
                    </RegularItem>
                  );
                })}
            </ContentGrid>
            {articlePage < numArticlePages - 1 ? (
              <StyledLink
                onClick={() => setArticlePage(articlePage + 1)}
                style={{ marginTop: "1.25rem" }}
              >
                Show more
              </StyledLink>
            ) : null}
          </>
        ) : null}
      </Styled.Results>
    </SectionWrapper>
  );
}
