import * as React from "react";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import { SectionOptions } from "components/ui/SectionWrapper";
import { TextH2 } from "styles/typography";
import { GridItem, Section } from "types/attheminute";

import * as Styled from "./BasicImageTextGrid.styled";
import FixedRatioBox from "components/ui/FixedRatioBox";
import { storyblokLoader } from "utils/storyblok";

export interface BasicImageTextGridProps extends Section {
  dataTestId?: string;
  className?: string;
  heading?: string;
  gridItems?: GridItem[];
}

export default function BasicImageTextGrid(props: BasicImageTextGridProps) {
  const {
    gridItems,
    heading,
    sectionOptions,
    className,
    dataTestId = "basic-image-text-grid-section",
  } = props;

  return (
    <SectionWrapper
      data-testid={dataTestId}
      className={className}
      {...getSectionWrapperProps(sectionOptions)}
    >
      {heading && <TextH2 noMarginTop>{heading}</TextH2>}
      {gridItems && gridItems.length > 0 ? (
        <Styled.Container>
          {gridItems.map((gridItem, idx) => {
            const Link = gridItem.link ? Styled.ActionLink : React.Fragment;
            return (
              <Styled.GridItem key={idx}>
                <Link link={gridItem.link}>
                  {gridItem.image && gridItem.image.url ? (
                    <FixedRatioBox
                      outerRatio={1}
                      innerRatio={
                        gridItem.image.dimensions
                          ? gridItem.image.dimensions?.width /
                            gridItem.image.dimensions?.height
                          : 1
                      }
                    >
                      <Styled.PreviewImage
                        src={gridItem.image.url}
                        alt="Resource Preview"
                        priority
                        layout="fill"
                        loader={storyblokLoader}
                      />
                    </FixedRatioBox>
                  ) : null}
                  {gridItem.heading && (
                    <Styled.Title noMarginTop={!gridItem.image}>{gridItem.heading}</Styled.Title>
                  )}
                  {gridItem.subheading && (
                    <Styled.Subtitle>{gridItem.subheading}</Styled.Subtitle>
                  )}
                </Link>
              </Styled.GridItem>
            );
          })}
        </Styled.Container>
      ) : null}
    </SectionWrapper>
  );
}
