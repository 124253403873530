import React from 'react';

const browserWindow = typeof window !== 'undefined' && window;

const getDimensions = () => ({
  height: browserWindow.innerHeight,
  width: browserWindow.innerWidth,
});

const useScreenSize = () => {
  const [windowSize, setWindowSize] = React.useState(getDimensions());

  const onResize = () => {
    setWindowSize(getDimensions());
  };

  React.useEffect(() => {
    onResize();
    browserWindow.addEventListener('resize', onResize);
    return () => {
      browserWindow.removeEventListener('resize', onResize);
    };
  }, []);

  return [windowSize.width, windowSize.height];
};

export default useScreenSize;
