import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import StoryblokRichtext from "components/ui/StoryblokRichtext";
import { colourNames } from "styles/colours";
import NextImage from "next/legacy/image";
import bioBlogSectionContent from "./content.json";

import * as Styled from "./TeachingBio.styled";
import SubscribeForm from "components/sections/EmailSubscribe/SubscribeForm";
import { TextH3, TextP } from "styles/typography";
import { Section } from "types/attheminute";

export default function TeachingBio({ sectionOptions: overriddenOptions }: Section) {
  const theme = "light" as "light" | "dark";
  const sectionOptions = {
    theme: theme,
    backgroundColour: colourNames.BACKGROUND,
    contentWidth: "regular" as const,
    ...overriddenOptions
  };

  const content = bioBlogSectionContent[0].content.content;

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      <Styled.Container>
        <Styled.Picture>
          <NextImage
            src={"/images/kath-potrait.png"}
            alt={"Katherine"}
            layout="intrinsic"
            width={500}
            height={500}
          />
        </Styled.Picture>
        <Styled.Bio>
          <StoryblokRichtext content={content} />
        </Styled.Bio>
        <Styled.Mission>
          <Styled.MissionTitle>
            My <Styled.MissionHighlight>mission</Styled.MissionHighlight> is for
            all of us to:
          </Styled.MissionTitle>
          <Styled.MissionMain>
            <div>Be purposeful teachers</div>
            <div>Who are in control</div>
            <div>Feel inspired</div>
            <span style={{ position: "relative" }}>
              {"And know we've done enough."}
              <Styled.TeachingUnderline colour="var(--color-mainBrandColour)" />
            </span>
          </Styled.MissionMain>
        </Styled.Mission>

        <Styled.SubscribeSection>
          <TextH3 noMarginTop>Join my weekly email list!</TextH3>
          <TextP>
            If that sounds like where you want to be, I’d love you to join me on
            making my mission a reality!
          </TextP>
          <TextP>
            Teaching is tough but together we can turn the tides and get back to
            the best parts; those reasons we got into teaching in the first
            place!
          </TextP>
          <SubscribeForm
            source={"teaching-home-page"}
            defaultEmailList={["09f94e86-0d60-4efb-a461-947c05e9d065"]}
            ctaButtonAppearance={{
              text: "Subscribe",
              appearance: "square",
              colourName: "warmAccent",
            }}
          />
        </Styled.SubscribeSection>
      </Styled.Container>
    </SectionWrapper>
  );
}
