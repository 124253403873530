import * as React from "react";
import CtaButton from "components/ui/CtaButton";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import { colourNames } from "styles/colours";
import useSubscribeToEmail from "hooks/useSubscribeToEmail";
import NextImage from "next/legacy/image";
import {
  TEACHING_EMAIL_LIST_ID,
  TEACHING_PLANNING_LEAD_MAGNET_LIST_ID,
} from "api/email";

import * as Styled from "./PlanningLeadMagnet.styled";
import { Section } from "types/attheminute";

export default function PlanningLeadMagnet({ sectionOptions: overriddenOptions }: Section) {
  const sectionOptions = {
    backgroundImage: "/images/yellow_splash_background.png",
    // theme: "lig" as 'light' | 'dark',
    disableOverlay: true,
    ...overriddenOptions,
  };

  const [email, setEmail] = React.useState("");
  const { subscribe, loading, data, error } = useSubscribeToEmail();

  let statusText = "";
  let success;

  if (data && !data.error && data.result !== "error") {
    statusText =
      "Great! You'll receive an email shortly with a link to your new planning templates!";
    success = true;
  } else if (error || (data && data.result === "error")) {
    statusText =
      "We've hit an error sending you the templates. Please try again or contact us at support@attheminute.com";
    success = false;
  }

  return (
    <SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      <Styled.InnerWrapper>
        <Styled.Grid>
          <Styled.TextContainer>
            <Styled.HeadingOne>
              Free digital planning templates pack!
            </Styled.HeadingOne>
            <Styled.Subheading>
              Ready to smash out your planning? Grab yourself this pack of
              planning templates so you can get back to what&apos;s really
              important: Creating your next inspiring lesson for your students!
            </Styled.Subheading>

            {/* Subscribe form */}
            <Styled.Form
              onSubmit={(e) => {
                e.preventDefault();
                subscribe(email, {
                  source: "planning-lead-magnet",
                  lists: [
                    TEACHING_EMAIL_LIST_ID,
                    TEACHING_PLANNING_LEAD_MAGNET_LIST_ID,
                  ],
                });
              }}
            >
              <Styled.Input
                placeholder="Email address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={loading}
                required
              />
              <Styled.UnstyledButton
                aria-label="Subscribe to mailing list"
                type="submit"
                disabled={loading}
              >
                <CtaButton
                  colourName={colourNames.MAIN_BRAND_COLOUR}
                  appearance="square"
                  aria-label="Download free!"
                  disabled={loading}
                >
                  Download free!
                </CtaButton>
              </Styled.UnstyledButton>
            </Styled.Form>
            <Styled.StatusText success={success}>
              {statusText}
            </Styled.StatusText>
          </Styled.TextContainer>

          <Styled.TextContainer>
            <Styled.HeroImageWrapper>
              <NextImage
                src={"/images/planning_templates_preview.png"}
                alt="Preview of free planning template resources"
                width={1200}
                height={847}
                unoptimized
              />
            </Styled.HeroImageWrapper>
          </Styled.TextContainer>
        </Styled.Grid>
      </Styled.InnerWrapper>
    </SectionWrapper>
  );
}
