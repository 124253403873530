import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { fontFamilyLabel, xlFont, TextH3, TextH4 } from "styles/typography";
import spacing from 'styles/spacing';
import NextImage from "next/legacy/image";
import DefaultActionLink from "components/ui/ActionLink";

export const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.xs};
  grid-template-columns: minmax(0, 1fr);

  ${screenSize.minTablet`
  grid-gap: ${spacing.md};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${screenSize.minDesktop`
  grid-gap: ${spacing.lg};
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}
`;

export const GridItem = styled.div`
  grid-column: span 1;
`;

export const Title = styled(TextH3)`
  color: inherit;
  margin-bottom: ${spacing.sm};
  font-size: ${xlFont};
`;

export const Subtitle = styled(TextH4)`
  font-family: ${fontFamilyLabel};
`;

export const PreviewImage = styled(NextImage)`
  border-radius: 4px;
`;

export const ActionLink = styled(DefaultActionLink)`
  color: var(--color-text);

  :hover {
    color: var(--color-warmAccent);
  }
`;

