import styled, { StyledComponent } from "styled-components";
import spacing from "styles/spacing";
import layout from "styles/layout";
import { TextH1, TextH2, TextH3, TextH4, TextP } from "styles/typography";
import screenSize from "styles/mediaQueries";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.md};
`;

// https://css-tricks.com/lobotomized-owls/
// > * + * This is the owl selector
export const ButtonsInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-top: ${spacing.sm};
  }

  ${screenSize.minTablet`
      flex-direction: row;
      
      > * + * {
        margin-left: ${spacing.sm};
        margin-top: 0;
  `}
`;

const getColor = (theme: "light" | "dark") => {
  switch (theme) {
    case "light":
      return "var(--color-dark)";
    case "dark":
      return "var(--color-light)";
    case undefined:
      return "var(--color-text)";
    default:
      return "var(--color-text)";
  }
};

interface TextAlignment {
  alignText?: "center" | "left" | "right";
}

interface HeadingProps extends TextAlignment {
  alignBottom?: boolean;
}

const tagged = (strings: TemplateStringsArray, ...expressions: any) => ({
  strings,
  expressions,
});

const headingStylesTagged = tagged`
  line-height: 1.5;
  max-width: ${layout.blogMaxWidth};
  grid-area: heading;
  color: ${({ theme }: { theme: "light" | "dark" }) => getColor(theme)};
  display: flex;
  margin-top: 0;
  margin-bottom: ${spacing.xs};
  margin-left: 0;
  margin-right: 0;
  ${(props: HeadingProps) =>
    props.alignBottom &&
    `
    align-self: flex-end;
  `}

  ${(props: HeadingProps) =>
    props.alignText === "center" &&
    `
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
  `}
  ${(props: HeadingProps) =>
    props.alignText === "left" &&
    `
    margin-left: 0;
    margin-right: auto;
    text-align: left;
    justify-content: flex-start;
  `}
  ${(props: HeadingProps) =>
    props.alignText === "right" &&
    `
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    justify-content: flex-end;
  `}


  ${screenSize.minTablet`
    line-height: 1.22;
    ${(props: HeadingProps) =>
      props.alignText === "center" &&
      `
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      justify-content: center;
    `}
    ${(props: HeadingProps) =>
      props.alignText === "left" &&
      `
      margin-left: 0;
      margin-right: auto;
      text-align: left;
      justify-content: flex-start;
    `}
    ${(props: HeadingProps) =>
      props.alignText === "right" &&
      `
      margin-right: 0;
      margin-left: auto;
      text-align: right;
      justify-content: flex-end;
    `}
    margin-bottom: ${spacing.md};
  `}
`;

export const HeadingH1 = styled(TextH1)<HeadingProps>(
  headingStylesTagged.strings,
  ...headingStylesTagged.expressions
);
export const HeadingH2 = styled(TextH2)<HeadingProps>(
  headingStylesTagged.strings,
  ...headingStylesTagged.expressions
);
export const HeadingH3 = styled(TextH3)<HeadingProps>(
  headingStylesTagged.strings,
  ...headingStylesTagged.expressions
);
export const HeadingH4 = styled(TextH4)<HeadingProps>(
  headingStylesTagged.strings,
  ...headingStylesTagged.expressions
);

export const SubHeading = styled(TextP)<TextAlignment>`
  grid-area: subheading;
  max-width: ${layout.blogMaxWidth};
  line-height: 1.57;
  font-weight: 400;
  color: ${({ theme }) => getColor(theme)};
  margin-top: 0;
  margin-bottom: 0;
  ${(props) =>
    props.alignText === "center" &&
    `
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: flex-center;
  `}
  ${(props) =>
    props.alignText === "left" &&
    `
    margin-left: 0;
    margin-right: auto;
    text-align: left;
    justify-content: flex-start;
  `}
  ${(props) =>
    props.alignText === "right" &&
    `
    margin-left: auto;
    margin-right: 0;
    text-align: right;
    justify-content: flex-end;
  `}

  ${screenSize.minTablet`
    line-height: 1.57;
  `}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${spacing.md};
  grid-template-areas:
    "img"
    "heading"
    "subheading";
  row-gap: ${spacing.md};

  ${screenSize.minTablet`
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'img heading'
      'img subheading';
    row-gap: 0;
  `}
`;

export const Image = styled.img`
  grid-area: img;
  justify-self: center;
  align-self: center;
  width: ${(props) => props.width};
  max-width: 100%;

  ${screenSize.minTablet`
    justify-self: end;
  `}
`;
