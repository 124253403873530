import AdvancedGridSection from "components/sections/AdvancedGrid/AdvancedGrid";
import ArticleGrid from "components/sections/ArticleGrid";
import BasicImageTextGrid from "components/sections/BasicImageTextGrid";
import BlogSection from "components/sections/BlogSection";
import EmailSubscribeImageText from "components/sections/EmailSubscribeImageText";
import ForegroundBackgroundHeroImageSection from "components/sections/ForegroundBackgroundHeroImageSection";
import HeaderAndCtaSection from "components/sections/HeaderAndCta";
import SideTextAndImage from "components/sections/SideTextAndImage";
import TeachingResourceGrid from "components/sections/TeachingResourceGrid";
import PlaceholderSection from "components/sections/placeholders";
import { SectionOptions } from "components/ui/SectionWrapper";
import { SectionContext } from "types/sectionContext";

const sectionMap = {
  advancedGridSection: AdvancedGridSection,
  articleGridSection: ArticleGrid,
  basicImageTextGridSection: BasicImageTextGrid,
  blogSection: BlogSection,
  emailSubscribeImageTextSection: EmailSubscribeImageText,
  foregroundBackgroundHeroImageSection: ForegroundBackgroundHeroImageSection,
  headerAndCtaSection: HeaderAndCtaSection,
  placeholderSection: PlaceholderSection,
  sideTextAndImageSection: SideTextAndImage,
  teachingResourceGridSection: TeachingResourceGrid,
} as const;

function SectionsMapper(props: {
  sections: SectionContext<any>[];
  noXPadding?: boolean;
}) {
  const { sections, noXPadding } = props;

  let overridenSectionOptions: Partial<SectionOptions> = {};
  if (noXPadding) {
    overridenSectionOptions.removeLeftPadding = true;
    overridenSectionOptions.removeRightPadding = true;
  }

  return (
    <>
      {sections.map((section, idx) => {
        const SectionComponent = sectionMap[section.component];

        if (!SectionComponent) return <div key={idx}>Section not found</div>;
        return (
          <SectionComponent
            key={section.id}
            {...section.props}
            sectionOptions={{
              ...(section.props.sectionOptions
                ? section.props.sectionOptions
                : {}),
              ...overridenSectionOptions,
            }}
          />
        );
      })}
    </>
  );
}

export default SectionsMapper;
