import * as React from "react";
import CtaButton from "components/ui/CtaButton";
import ActionLink from "components/ui/ActionLink";
import SectionWrapper, {
  getSectionWrapperProps,
} from "components/ui/SectionWrapper";
import { TextH1, TextH2, TextH3, TextH4 } from "styles/typography";
import useScreenSize from "hooks/useScreenSize"; // TODO: Remove the need for this hook. Ideally can use only css.
import { MIN_TABLET } from "styles/mediaQueries";
import { CallToAction, Section } from "types/attheminute";
import { SectionOptions } from 'components/ui/SectionWrapper';

import * as Styled from "./HeaderAndCta.styled";

function EmptyWrapper({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

export const headingComponentMap = {
  h1: Styled.HeadingH1,
  h2: Styled.HeadingH2,
  h3: Styled.HeadingH3,
  h4: Styled.HeadingH4,
};

export type HeadingHtmlTagType = keyof typeof headingComponentMap;

export interface HeaderAndCtaSectionProps extends Section {
  headingHTMLTag?: HeadingHtmlTagType;
  dataTestId?: string;
  heading?: string;
  subheading?: string;
  optionalImageSrc?: string;
  optionalImageAlt?: string;
  imageWidth?: string | number;
  alignText?: "left" | "center" | "right";
  callToActions?: CallToAction[];
  className?: string;
}

export default function HeaderAndCtaSection(props: HeaderAndCtaSectionProps) {
  const {
    heading,
    headingHTMLTag,
    subheading = "",
    callToActions = [],
    sectionOptions,
    dataTestId = "header-and-cta-section",
    optionalImageSrc,
    optionalImageAlt,
    imageWidth = 200,
    alignText = "center",
    className,
  } = props;

  const [screenWidth] = useScreenSize();

  const hasImage = !!optionalImageSrc;
  const ContentWrapper = hasImage ? Styled.Content : EmptyWrapper;

  const imageAlt = optionalImageAlt;
  const imageSrc = optionalImageSrc;
  const imageWidthNumber =
    typeof imageWidth === "number" ? imageWidth : Number(imageWidth);

  const theme = sectionOptions ? sectionOptions.theme : undefined;

  let alignTextActual = alignText;
  if (screenWidth < MIN_TABLET) {
    alignTextActual = "center";
  }

  const HeadingComponent = headingHTMLTag
    ? headingComponentMap[headingHTMLTag]
    : Styled.HeadingH1;

  return (
    <SectionWrapper
      data-testid={dataTestId}
      className={className}
      {...getSectionWrapperProps(sectionOptions)}
    >
      <ContentWrapper>
        {hasImage && (
          <Styled.Image
            src={imageSrc}
            alt={imageAlt}
            width={imageWidthNumber}
          />
        )}
        <HeadingComponent
          alignBottom={!!hasImage}
          alignText={alignTextActual}
          theme={theme}
        >
          {heading}
        </HeadingComponent>
        <Styled.SubHeading alignText={alignTextActual} theme={theme}>
          {subheading}
        </Styled.SubHeading>
      </ContentWrapper>

      {!!callToActions.length && (
        <Styled.ButtonsWrapper>
          <Styled.ButtonsInnerWrapper>
            {callToActions.map((ctaButton) => (
              <ActionLink
                key={ctaButton.id}
                dataTestId={`call-to-action-${ctaButton.id}`}
                link={ctaButton.link}
                onClick={ctaButton.onClick}
              >
                <CtaButton
                  colourName={ctaButton.colourName}
                  appearance={ctaButton.appearance}
                  aria-label={ctaButton.text}
                >
                  {ctaButton.text}
                </CtaButton>
              </ActionLink>
            ))}
          </Styled.ButtonsInnerWrapper>
        </Styled.ButtonsWrapper>
      )}
    </SectionWrapper>
  );
}
