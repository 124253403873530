import * as React from "react";
import CtaButton from "components/ui/CtaButton";
import { getSectionWrapperProps } from "components/ui/SectionWrapper";
import { colourNames } from "styles/colours";

import * as Styled from "./TeachingHero.styled";
import ActionLink from "components/ui/ActionLink";
import dynamic from "next/dynamic";
import { Section } from "types/attheminute";

export const TeachingHeroCircleSvg = dynamic(() =>
  import("./TeachingHeroCircleSvg").then((mod) => mod)
);

export default function TeachingHero({ sectionOptions: overriddenOptions }: Section) {
  const theme = "light" as "light" | "dark";
  const sectionOptions = {
    theme: theme,
    backgroundColour: colourNames.LIGHT_SHADES,
    ...overriddenOptions,
  };

  return (
    <Styled.SectionWrapper {...getSectionWrapperProps(sectionOptions)}>
      <Styled.InnerWrapper>
        <Styled.Grid>
          <Styled.TextContainer>
            <Styled.HeadingOne>
              Get back to what you love about{" "}
              <Styled.TeachingText>
                teaching
                <Styled.TeachingUnderline />
              </Styled.TeachingText>
            </Styled.HeadingOne>
            <Styled.Subheading>
              {"Resources, guides, tips, inspiration & classroom tools"}
            </Styled.Subheading>

            <ActionLink
              dataTestId={`teaching-hero-call-to-action`}
              link={{
                id: "teaching-resources",
                component: "internalLink",
                newTab: false,
                url: "/teaching/shop",
              }}
            >
              <CtaButton
                colourName={colourNames.MAIN_BRAND_COLOUR}
                appearance={"square"}
              >
                Teaching Resources
              </CtaButton>
            </ActionLink>
          </Styled.TextContainer>

          <Styled.ImageContainer>
            <Styled.HeroImageWrapper>
              <TeachingHeroCircleSvg />
            </Styled.HeroImageWrapper>
          </Styled.ImageContainer>
        </Styled.Grid>
      </Styled.InnerWrapper>
    </Styled.SectionWrapper>
  );
}
