import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import DefaultSectionWrapper from "components/ui/SectionWrapper";
import spacing from "styles/spacing";
import layout from "styles/layout";
import {
  fontFamilyBrand,
  TextH1,
  TextP,
  heroFont,
  mHeroFont,
  fontFamilyLabel,
} from "styles/typography";
import Underline from "./Underline";

interface InnerWrapperProps {
  isFlipped?: boolean;
}

export const SectionWrapper = styled(DefaultSectionWrapper)`
  ${screenSize.minTablet`
    padding: 6.25rem 0.375rem;
  `}

  ${screenSize.minDesktop`
  padding: 10rem 3.75rem;
`}
`;

export const InnerWrapper = styled.div<InnerWrapperProps>`
  width: 100%;
  margin: auto;
  max-width: ${layout.contentMaxWidth};
  flex-direction: ${(props) => (props.isFlipped ? "row-reverse" : "row")};
  display: flex;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: none;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left
  `}

  ${screenSize.minDesktop`
    width: 100%;
  `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: none;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left
  `}

  ${screenSize.minDesktop`
    width: 100%;
  `}
`;

export const HeadingOne = styled(TextH1)`
  color: var(--color-text);
  max-width: 28.125rem;
  font-size: 1.75rem;
  line-height: 1.5;
`;

export const Subheading = styled(TextP)`
  font-family: ${fontFamilyLabel};
  color: var(--color-text);
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${spacing.md};

  grid-template-columns: 1fr;

  ${screenSize.minTablet`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const CtaWrapper = styled.div`
  margin-top: ${spacing.lg};
`;

export const HeroImageWrapper = styled.div`
  width: 100%;
  max-width: 385px;
  border-radius: 4px;
  > svg {
    width: 100%;
  }
`;

export const TeachingText = styled.span`
  font-family: ${fontFamilyBrand};
  color: var(--color-verdigris);
  position: relative;
  font-size: ${mHeroFont};

  ${screenSize.minTablet`
    font-size: ${heroFont};
  `}
`;

export const TeachingUnderline = styled(Underline)`
  position: absolute;
  bottom: -0.625rem;
  left: 0;
  width: 100%;
`;
